// Overriding variables from the minima theme
$base-font-family: Merriweather, "Charis SIL", serif;
$content-width: 40em;
// Default is #2a7ae2 and Google complained about low contrast for smaller links
$brand-color: #2873d5;
// Default is #828282 and Google complained about low contrast
$grey-color: #555555;

// Import minima and make it set all its CSS rules
@import "minima";

// Override particular CSS that the minima theme created

// Override the ugly ass border at the top they seem to like
.site-header {
  border-top: 0px;
}

p {
  text-align: justify;
}

// Some definition list styling.
dt {
  font-weight: bold;
}
dd {
  margin-left: 3em;
}

// Styling of keyboard buttons as per Wikipedia
kbd {
  border: 1px solid;
  border-color: #ddd #bbb #bbb #ddd;
  border-bottom-width: 2px;
  border-radius: 3px;
  background-color: #f9f9f9;
  padding: 1px 3px;
  //font-family: inherit;
  white-space: nowrap;
}

h1.post-title {
  text-align: center;
}
